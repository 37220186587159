import AxiosService from "@/services/AxiosService";

class siteSettingsService {
  async getNativeWebPush(id) {
    return await AxiosService.get(`/subaccount/${id}/native-web-push`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setNativeWebPush(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/native-web-push`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setSubAccountFormat(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/settings-status`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getInPage(id) {
    return await AxiosService.get(`/subaccount/${id}/in-page`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setInPage(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/in-page`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getFormats(id, payload) {
    return await AxiosService.get(`/subaccount/${id}/settings-status`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getServiceWorker(payload) {
    return await AxiosService.get(
      `/service-worker?worker_name=${payload.name}&subaccount_id=${payload.id}`,
      {}
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async checkServiceWorker(payload) {
    return await AxiosService.post("/check-service-worker", payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getOnClick(payload) {
    return await AxiosService.get(`/subaccount/${payload}/on-click`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setOnClick(payload) {
    return await AxiosService.post(
      `/subaccount/${payload.id}/on-click`,
      payload
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getClickPush(payload) {
    return await AxiosService.get(`/subaccount/${payload}/two-click-push`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setClickPush(payload) {
    return await AxiosService.post(
      `/subaccount/${payload.id}/two-click-push`,
      payload
    )
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getZeroClickPush(id) {
    return await AxiosService.get(`/subaccount/${id}/zero-click-push`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async setZeroClickPush(id, payload) {
    return await AxiosService.post(`/subaccount/${id}/zero-click-push`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getOsVersions() {
    return AxiosService.get("/get-os-versions")
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default new siteSettingsService();
